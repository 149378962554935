export const bundleProductID = 650576;

export const bundleProductNode = {
  frontmatter: {
    product_id: bundleProductID,
    title: 'Bundle: All PromLabs Trainings',
    price: 349,
  },
};

export const refLocalStorageKey = 'promlabs-trainings.ref';
export const newTitleLocalStorageKey = 'promlabs-trainings.new-title';
